import Link from "next/link";
import Styles from "@/styles/Banner.module.css";
import Image from "next/image";

const BannerSection = ({ newsBanner }) => {
  return (
    <>
      <div className="col-lg-7">
        <div className="row">
          {newsBanner.map((item, index) =>
            index == 0 ? (
              <div className="col-sm-12 nopadding" key={index + item.slug}>
                <article
                  className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__3} ${Styles.thumb__mb__0} `}
                  key={index + item.slug}
                  itemScope
                  itemType="http://schema.org/NewsArticle"
                >
                  {/* <Link href="https://election.tbn24.com" target="_blank">
                    <iframe
                      src="https://election.tbn24.com/live-result"
                      width="100%"
                      height="100%"
                      style={{ border: "none", overflow: "hidden" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      scrolling="no"
                    ></iframe>
                  </Link> */}
                  <Image
                    alt={item.image_caption}
                    src={
                      item.image_url
                        ? item.image_url
                        : item.image
                        ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                        : ""
                    }
                    fill
                    priority
                    quality={100}
                    loading="eager"
                    sizes="(max-width: 412px) 100vw, (max-width: 992px) 33vw, (max-width: 1200px) 23vw, 22vw"
                    className={`${Styles.entry__img__holder} `}
                    style={{
                      objectFit: "cover",
                    }}
                    blurDataURL="URL"
                    placeholder="blur"
                  />
                  <div className={`${Styles.bottom__gradient}`}></div>
                  <Link
                    // href="https://election.tbn24.com"
                    // target="_blank"
                    href={"/news/" + item.slug}
                    aria-label={item.title}
                    prefetch={false}
                    itemProp="url"
                  >
                    <h2
                      className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                      itemProp="name"
                    >
                      {/* সরাসরি নির্বাচন-২০২৪ এর ফলাফল দেখুন এখানে... */}
                      {item.title}
                    </h2>
                  </Link>
                </article>
              </div>
            ) : index == 1 ? (
              <div
                className="col-sm-6 col-6 nopaddingleft"
                style={{
                  float: "left",
                }}
                key={index + item.slug}
              >
                <article
                  className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin margintop15`}
                  style={{
                    padding: "0px",
                  }}
                  itemScope
                  itemType="http://schema.org/NewsArticle"
                >
                  <Image
                    alt={item.image_caption}
                    src={
                      item.image_url
                        ? item.image_url
                        : item.image
                        ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                        : ""
                    }
                    quality={100}
                    sizes="(max-width: 412px) 50vw, (max-width: 992px) 24vw, (max-width: 1200px) 23vw, 22vw"
                    fill
                    priority
                    loading="eager"
                    className={`${Styles.entry__img__holder} `}
                    style={{
                      objectFit: "cover",
                    }}
                    blurDataURL="URL"
                    placeholder="blur"
                  />
                  <div className={`${Styles.bottom__gradient}`}></div>
                  <Link
                    href={"/news/" + item.slug}
                    aria-label={item.title}
                    prefetch={false}
                    itemProp="url"
                  >
                    <h2
                      className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                      itemProp="name"
                    >
                      {" "}
                      {item.title}
                    </h2>
                  </Link>
                </article>
              </div>
            ) : index == 2 ? (
              <div
                className="col-sm-6 col-6 nopaddingright"
                style={{
                  float: "left",
                }}
                key={index + item.slug}
              >
                <article
                  className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin margintop15`}
                  style={{
                    padding: "0px",
                  }}
                  itemScope
                  itemType="http://schema.org/NewsArticle"
                >
                  <Image
                    alt={item.image_caption}
                    src={
                      item.image_url
                        ? item.image_url
                        : item.image
                        ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                        : ""
                    }
                    quality={100}
                    sizes="(max-width: 412px) 50vw, (max-width: 992px) 33vw, (max-width: 1200px) 25vw, 20vw"
                    fill
                    priority
                    loading="eager"
                    className={`${Styles.entry__img__holder} `}
                    style={{
                      objectFit: "cover",
                    }}
                    blurDataURL="URL"
                    placeholder="blur"
                  />
                  <div className={`${Styles.bottom__gradient}`}></div>
                  <Link
                    href={"/news/" + item.slug}
                    aria-label={item.title}
                    prefetch={false}
                    itemProp="url"
                  >
                    <h2
                      className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                      itemProp="name"
                    >
                      {item.title}
                    </h2>
                  </Link>
                </article>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
      <div className="col-lg-5 " style={{ padding: "0px" }}>
        {newsBanner.map((item, index) =>
          index == 3 ? (
            <div
              className="col-lg-12 col-sm-6 col-6 nopaddingrightside"
              style={{ float: "left" }}
              key={index + item.slug}
            >
              <article
                className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin marginbottom15`}
                style={{
                  padding: "0px",
                }}
                itemScope
                itemType="http://schema.org/NewsArticle"
              >
                <Image
                  alt={item.image_caption}
                  src={
                    item.image_url
                      ? item.image_url
                      : item.image
                      ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                      : ""
                  }
                  sizes="(max-width: 412px) 50vw, (max-width: 992px) 33vw, (max-width: 1200px) 25vw, 20vw"
                  fill
                  priority
                  loading="eager"
                  quality={100}
                  className={`${Styles.entry__img__holder} `}
                  style={{
                    objectFit: "cover",
                  }}
                  blurDataURL="URL"
                  placeholder="blur"
                />
                <div className={`${Styles.bottom__gradient}`}></div>
                <Link
                  href={"/news/" + item.slug}
                  aria-label={item.title}
                  prefetch={false}
                  itemProp="url"
                >
                  <h2
                    className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                    itemProp="name"
                  >
                    {item.title}
                  </h2>
                </Link>
              </article>
            </div>
          ) : index == 4 ? (
            <div
              className="col-lg-12 col-sm-6 col-6 nopaddingrightside"
              style={{ float: "left" }}
              key={index + item.slug}
            >
              <article
                className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin marginbottom15`}
                style={{
                  padding: "0px",
                }}
                itemScope
                itemType="http://schema.org/NewsArticle"
              >
                <Image
                  alt={item.image_caption}
                  src={
                    item.image_url
                      ? item.image_url
                      : item.image
                      ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                      : ""
                  }
                  sizes="(max-width: 412px) 50vw, (max-width: 992px) 33vw, (max-width: 1200px) 25vw, 20vw"
                  fill
                  priority
                  loading="eager"
                  quality={100}
                  className={`${Styles.entry__img__holder} `}
                  style={{
                    objectFit: "cover",
                  }}
                  blurDataURL="URL"
                  placeholder="blur"
                />
                <div className={`${Styles.bottom__gradient}`}></div>
                <Link
                  href={"/news/" + item.slug}
                  aria-label={item.title}
                  prefetch={false}
                  itemProp="url"
                >
                  <h2
                    className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                    itemProp="name"
                  >
                    {item.title}
                  </h2>
                </Link>
              </article>
            </div>
          ) : index == 5 ? (
            <div
              className="col-lg-12 col-sm-12 col-12 nopaddingrightside"
              style={{ float: "left" }}
              key={index + item.slug}
            >
              <article
                className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin marginbottom15`}
                style={{
                  padding: "0px",
                }}
                itemScope
                itemType="http://schema.org/NewsArticle"
              >
                <Image
                  alt={item.image_caption}
                  src={
                    item.image_url
                      ? item.image_url
                      : item.image
                      ? process.env.REACT_APP_DATA_IMAGE_URL + item.image
                      : ""
                  }
                  quality={100}
                  sizes="(max-width: 412px) 50vw, (max-width: 992px) 33vw, (max-width: 1200px) 25vw, 20vw"
                  fill
                  priority
                  loading="eager"
                  className={`${Styles.entry__img__holder} `}
                  style={{
                    objectFit: "cover",
                  }}
                  blurDataURL="URL"
                  placeholder="blur"
                />
                <div className={`${Styles.bottom__gradient}`}></div>

                <Link
                  href={"/news/" + item.slug}
                  aria-label={item.title}
                  prefetch={false}
                  itemProp="url"
                >
                  <h2
                    className={`${Styles.thumb__text__holder} ${Styles.thumb__text__holder__2} ${Styles.thumb__entry__title} `}
                    itemProp="name"
                  >
                    {item.title}{" "}
                  </h2>
                </Link>
              </article>
            </div>
          ) : (
            ""
          )
        )}
      </div>
    </>
  );
};

export default BannerSection;
