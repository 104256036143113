"use client";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useEffect, useState } from "react";
import Skeleton from "../Skeleton";
import AdsImages from "../AdsImages";
// import Survey from "../survey/Survey";
const NewsSliderNews = dynamic(() =>
  import("@/components/sliders/NewsSliderNews")
);
const TopNewsBD = dynamic(() => import("@/components/top-news-bd/TopNews"));
const NewsSingleSmall = dynamic(() => import("@/components/NewsSingleSmall"));

const CategoryNewsBigNews = dynamic(() =>
  import("@/components/home-page/CategoryNewsBigNews")
);

const CategoryNewsSingle = dynamic(() =>
  import("@/components/home-page/CategoryNewsSingle")
);
const MoreNews = dynamic(() => import("@/components/home-page/MoreNews"));
const VideoSliderNews = dynamic(() =>
  import("@/components/sliders/VideoSliderNews")
);
const NewsSingle = dynamic(() => import("@/components/NewsSingle"));
const AllOthers = () => {
  const [latestNews, setLatestNews] = useState([]);
  const [categoriesNews, setCategoriesNews] = useState([]);
  const [moreNewsData, setMoreNewsData] = useState([]);
  const [videosNews, setVideosNews] = useState([]);

  useEffect(() => {
    getLatestNewsData();
    getCategorysNewsData();
    getMoreNewsData();
    // getTopPhotosData();
    getVideosNewsData();
    // getPhotosNewsData();
  }, []);
  const getLatestNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL +
          "/frontend-bn/home/latest/news/list/data"
      );
      setLatestNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const getCategorysNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL +
          "/frontend-bn/categories/news/list/data",
        { next: { revalidate: 60 } }
      );
      setCategoriesNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const getMoreNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/more-news/list",
        { next: { revalidate: 60 } }
      );
      setMoreNewsData(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  const getVideosNewsData = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/videos/news/list/data",
        { next: { revalidate: 60 } }
      );
      setVideosNews(await res.json());
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="container-fluid category-section-layout">
        <div className="row">
          <div className="col-lg-9 mb-5">
            <Link
              href={"/news"}
              aria-label={"সবশেষ সংবাদ"}
              prefetch={false}
              className="section-title"
            >
              <h2 className="title">সবশেষ সংবাদ</h2>
            </Link>
            <div className="row">
              {latestNews.length > 0 ? (
                latestNews.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 grid-layout"
                    key={index}
                    itemScope
                    itemType="http://schema.org/NewsArticle"
                  >
                    <NewsSingle item={item} />
                  </div>
                ))
              ) : (
                <>
                  {[...Array(9)].map((e, i) => (
                    <div className="col-md-4" key={i}>
                      <Skeleton type={"grid"} width={"100%"} height={"330px"} />
                    </div>
                  ))}
                </>
              )}{" "}
            </div>
            {latestNews && (
              <div className="col-md-12 text-center">
                <Link
                  href="/news"
                  className="btn btn-primary btn-sm"
                  aria-label={"আরও নিউজ"}
                  prefetch={false}
                >
                  আরও নিউজ
                </Link>
              </div>
            )}
          </div>
          <div className="col-lg-3">
            {/* <Survey /> */}
            <AdsImages
              type="SIDEBAR"
              url={"/frontend-bn/home/business-deals/home_sidebar"}
              padding={"0px 0px 15px"}
            />
            <TopNewsBD title={"বাংলাদেশ"} />
          </div>
        </div>
      </div>
      {videosNews.length > 0 && (
        <VideoSliderNews title={"সবশেষ  ভিডিও"} videosNews={videosNews} />
      )}
      <div className="container-fluid category-section-layout">
        {categoriesNews.length > 0 &&
          categoriesNews.map((item, index) => (
            <div className="row mt-4" key={index}>
              <div className="col-sm-12">
                <CategoryNewsSingle
                  key={index}
                  news={item.news}
                  category={item.category}
                  subcategories={item.subcategories}
                />
              </div>

              <CategoryNewsBigNews category={item.category} news={item.news} />
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="row">
                  {item.news.map((item, index) =>
                    index == 1 || index == 2 ? (
                      <div
                        className="col-lg-6 col-md-6 col-sm-12 col-md-6 grid-layout"
                        key={index}
                        itemScope
                        itemType="http://schema.org/NewsArticle"
                      >
                        <NewsSingle item={item} />
                      </div>
                    ) : index > 2 ? (
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-md-12 grid-layout w-100 p-3"
                        key={index}
                        style={{ display: "block" }}
                        itemScope
                        itemType="http://schema.org/NewsArticle"
                      >
                        <NewsSingleSmall item={item} />
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* {photosNews.length > 0 && (
            <PhotoSliderNews title={"সবশেষ  ফটো"} photosNews={photosNews} />
          )} */}
      <NewsSliderNews type={"subCategory"} title={"অ্যামেরিকায় জীবনযাপন"} />
      <div className="container-fluid">
        {moreNewsData.length > 0 && <MoreNews moreNewsData={moreNewsData} />}
        <AdsImages
          type="FOOTER"
          url={"/frontend-bn/home/business-deals/home_before_footer"}
          padding={"15px 0px 15px 0px"}
        />
      </div>
    </>
  );
};

export default AllOthers;
