"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Styles from "@/styles/Banner.module.css";
import StylesIcon from "@/styles/Icons.module.css";
import Skeleton from "@/components/Skeleton";
import BannerSection from "@/components/home-page/BannerSection";
import AdsImagesHomePage from "../AdsImagesHomePage";
import Link from "next/link";
const TopNewsVideoSingle = dynamic(() =>
  import("@/components/top-news-videos/TopNewsVideoSingle")
);
const YoutubeModal = dynamic(() => import("@/components/modals/YoutubeModal"));

const Slider = ({ newsBannerInitials, videosInitials, serverData }) => {
  const [newsBanner, setNewsBanner] = useState(
    newsBannerInitials.length > 0 ? newsBannerInitials : []
  );

  const [topVideos, setTopVideos] = useState(
    videosInitials.length > 0 ? videosInitials : []
  );
  const [topVideosCount, setTopVideosCount] = useState(0);
  const [openYoutubeModal, setOpenYoutubeModal] = useState(false);
  const [dataVideoLink, setDataVideoLink] = useState([]);
  const [dataTitle, setDataTitle] = useState([]);
  const [dataShortdescription, setDataShortdescription] = useState([]);

  const setTopVideosPrev = () => {
    if (topVideosCount != 0) {
      setTopVideosCount((prevState) => prevState - 1);
    }
  };
  const setTopVideosNext = () => {
    if (topVideosCount != 2) {
      setTopVideosCount((prevState) => prevState + 1);
    }
  };
  const viewYoutube = (dataVideoLink, dataTitle, dataShortdescription) => {
    setOpenYoutubeModal(true);
    setDataVideoLink(dataVideoLink);
    setDataTitle(dataTitle);
    setDataShortdescription(dataShortdescription);
  };

  useEffect(() => {
    if (newsBannerInitials.length == 0) {
      getData();
    }
    if (videosInitials.length == 0) {
      getVideosData();
    }
  }, []);

  const getData = async () => {
    try {
      const resBannerNews = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/banner-news/list"
      );
      const newsBanner = await resBannerNews.json();

      setNewsBanner(newsBanner);
    } catch (err) {
      console.log(err);
    }
  };
  const getVideosData = async () => {
    try {
      const resVideos = await fetch(
        process.env.REACT_APP_DATA_URL + "/frontend-bn/videos/top-videos/data",
        { next: { revalidate: 60 } }
      );
      const videos = await resVideos.json();
      setTopVideos(videos);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {newsBanner.length > 0 ? (
        <div className="row ">
          <div className="col-sm-8 col-md-8 col-lg-9 col-xl-9 order-sm-2">
            <div className="row">
              <BannerSection newsBanner={newsBanner} />

              <AdsImagesHomePage
                type="SLIDER"
                url={"/frontend-bn/home/business-deals/home_after_slider"}
                padding={"0px 15px 15px 0px"}
                serverData={serverData}
              />
            </div>
          </div>

          <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3  order-sm-1">
            <div className="sidebar order-lg-1 widget widget-headlines">
              <Link href={"/videos"} title={"শীর্ষ ভিডিও"} prefetch={false}>
                <h2 className="widget-title" title="TBN24 শীর্ষ ভিডিও">
                  শীর্ষ ভিডিও
                </h2>
              </Link>
              {topVideosCount == 0
                ? topVideos.map((item, index) =>
                    item.description == "This video is private." ||
                    item.title == "Private video" ||
                    item.title == "Deleted video" ? (
                      ""
                    ) : index < 5 ? (
                      <div
                        className={"grid-layout"}
                        onClick={() =>
                          viewYoutube(
                            item.video_links ? item.video_links : item.video,
                            item.title,
                            item.description
                          )
                        }
                        key={index}
                        style={{
                          margin: "0px",
                          display: "block",
                          padding: "0px 15px",
                        }}
                      >
                        <TopNewsVideoSingle item={item} />
                        <hr />
                      </div>
                    ) : (
                      ""
                    )
                  )
                : topVideosCount == 1
                ? topVideos &&
                  topVideos.map((item, index) =>
                    item.description == "This video is private." ||
                    item.title == "Private video" ||
                    item.title == "Deleted video" ? (
                      ""
                    ) : index > 4 && index < 10 ? (
                      <div
                        className={"grid-layout"}
                        onClick={() =>
                          viewYoutube(
                            item.video_links ? item.video_links : item.video,
                            item.title,
                            item.description
                          )
                        }
                        key={index}
                        style={{
                          margin: "0px",
                          display: "block",
                          padding: "0px 15px",
                        }}
                      >
                        <TopNewsVideoSingle item={item} />
                        <hr />
                      </div>
                    ) : (
                      ""
                    )
                  )
                : topVideos &&
                  topVideos.map((item, index) =>
                    item.description == "This video is private." ||
                    item.title == "Private video" ||
                    item.title == "Deleted video" ? (
                      ""
                    ) : index > 9 && index < 15 ? (
                      <div
                        className={"grid-layout"}
                        onClick={() =>
                          viewYoutube(
                            item.video_links ? item.video_links : item.video,
                            item.title,
                            item.description
                          )
                        }
                        key={index}
                        style={{
                          margin: "0px",
                          display: "block",
                          padding: "0px 15px",
                        }}
                      >
                        <TopNewsVideoSingle item={item} />
                        <hr />
                      </div>
                    ) : (
                      ""
                    )
                  )}
              {topVideos && topVideos.length > 0 ? (
                <div style={{ width: "100%" }}>
                  <div className="text-center custom-slider-btns">
                    <button
                      type="button"
                      aria-label="Prev Video"
                      title="Prev Video"
                      className="custom-slider-left-btn"
                      onClick={setTopVideosPrev}
                    >
                      <i
                        className={`${StylesIcon.chevron__left}`}
                        style={{ color: "#fff" }}
                      ></i>
                    </button>
                    <button
                      type="button"
                      aria-label="Next Video"
                      title="Next Video"
                      className="custom-slider-right-btn"
                      onClick={setTopVideosNext}
                    >
                      <i
                        className={`${StylesIcon.chevron__right}`}
                        style={{ color: "#fff" }}
                      ></i>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              {openYoutubeModal && (
                <YoutubeModal
                  openYoutubeModal={openYoutubeModal}
                  setOpenYoutubeModal={setOpenYoutubeModal}
                  dataVideoLink={dataVideoLink}
                  dataTitle={dataTitle}
                  dataShortdescription={dataShortdescription}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-8 col-md-8 col-lg-9 col-xl-9 order-sm-2">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-sm-12 nopadding">
                    <article
                      className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__3} ${Styles.thumb__mb__0} `}
                    >
                      <Skeleton type="image" width={"100%"} height={"380px"} />
                    </article>
                  </div>
                  <div
                    className="col-sm-6 col-6 nopaddingleft"
                    style={{
                      float: "left",
                    }}
                  >
                    <article
                      className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin margintop15`}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <Skeleton type="image" width={"100%"} height={"182px"} />
                    </article>
                  </div>
                  <div
                    className="col-sm-6 col-6 nopaddingright"
                    style={{
                      float: "left",
                    }}
                  >
                    <article
                      className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin margintop15`}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <Skeleton type="image" width={"100%"} height={"182px"} />
                    </article>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 " style={{ padding: "0px" }}>
                {[...Array(2)].map((e, i) => (
                  <div
                    className="col-lg-12 col-sm-6 col-6 nopaddingrightside"
                    style={{ float: "left" }}
                    key={i}
                  >
                    <article
                      className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin marginbottom15`}
                      style={{
                        padding: "0px",
                      }}
                    >
                      <Skeleton type="image" width={"100%"} height={"182px"} />
                    </article>
                  </div>
                ))}
                <div
                  className="col-lg-12 col-sm-12 col-12 nopaddingrightside"
                  style={{ float: "left" }}
                >
                  <article
                    className={`${Styles.entry} ${Styles.thumb} ${Styles.thumb__size__1} nomargin marginbottom15`}
                    style={{
                      padding: "0px",
                    }}
                  >
                    <Skeleton type="image" width={"100%"} height={"182px"} />
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3  order-sm-1">
            <div className="sidebar order-lg-1 widget widget-headlines">
              <h2 className="widget-title" title="TBN24 শীর্ষ ভিডিও">
                শীর্ষ ভিডিও
              </h2>
              <div className="col-md-12">
                {[...Array(5)].map((e, i) => (
                  <Skeleton
                    type={"small-grid"}
                    width={"100%"}
                    height={"85px"}
                    key={i}
                  />
                ))}
                <div className="text-center custom-slider-btns">
                  <button
                    type="button"
                    aria-label="Prev Video"
                    title="Prev Video"
                    className="custom-slider-left-btn"
                    onClick={setTopVideosPrev}
                  >
                    <i
                      className={`${StylesIcon.chevron__left}`}
                      style={{ color: "#fff" }}
                    ></i>
                  </button>
                  <button
                    type="button"
                    aria-label="Next Video"
                    title="Next Video"
                    className="custom-slider-right-btn"
                    onClick={setTopVideosNext}
                  >
                    <i
                      className={`${StylesIcon.chevron__right}`}
                      style={{ color: "#fff" }}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
